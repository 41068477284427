<template>
    <div v-if="loader">
        Loading...
    </div>

    <div v-if="!loader" class="forgot_password_cont">
        <h1>Forgot your password?</h1>
        <div id="success" style="color: green; text-align: center;">{{success}}</div>
        <div id="error" style="color: red; text-align: center;">{{error}}</div>
        <input class placeholder="Mail" v-model="email" v-if="status === 'insert-email' || status === 'insert-password'">
        <input placeholder="XXX-XXX" v-model="otp" v-if="status === 'insert-password'">
        <input type = "password" placeholder="Your New Password" v-model="new_password" v-if="status === 'reset-password'">
        <button v-if="status === 'insert-email'" v-on:click="send_new_password">Send New Password</button>
        <button v-if="status === 'insert-password'" v-on:click="validate_otp">Validate OTP</button>
        <button v-if="status === 'reset-password'" v-on:click="reset_password">Reset Password</button>
    </div>
</template>

<script>
import axios from 'axios';
import BackendModel from "../../models/BackendModel";
const utils = new BackendModel()


export default {
    name: 'Backoffice',
    components: {

    },
    data() {
        return {
            email: "",
            success: "",
            error: "",
            status: "insert-email",
            otp: "",
            new_password: "",
            loader: false
        }
    },
    methods: {
        async send_new_password() {
            this.loader = true;
            const result = await axios.post(utils.getBaseUrl() + "/Api/service/backoffice/forgot_password", {email: this.email})
            if (result.data.res === 'NO EMAIL') {
                this.error = 'Plaese insert your email address';
            }
            else if (result.data.res === 'EMAIL ERROR') {
                this.error = "Your email address doesn't exist. please try again";

            }
            else if (result.data.res === 'OK') {
                this.error = '';
                this.status = "insert-password";
                this.success = "Please Check your email for the OTP, Provide the OTP to change the password";
            }
            else {
                this.error = "Something is wrong. Please try again";
            }
            this.loader = false;
        },

        async validate_otp() {
            this.loader = true;
            this.success = '';
            this.error = '';
            const result = await axios.post(utils.getBaseUrl() + "/Api/service/backoffice/validate_otp", {email: this.email, otp: this.otp})
            this.loader = false;
            if (result.data.res === 'OK') {
                this.error = '';
                this.status = 'reset-password'
                this.success = 'Great, choose your new password and click on RESET';
            }
            else if (result.data.res === 'NOK') {
                this.error = 'your OTP is incorrect. Please try again';
            }
            else if (result.data.res === 'NO FIELDS') {
                this.error = 'Your OTP is empty. Please check your email for the OTP, provide the OTP to change the password';
            }
            else {
                this.error = "Something is wrong. Please try again";
            }
            this.loader = false;
        },

        async reset_password() {
            this.loader = true;
            this.success = '';
            this.error = '';
            const result = await axios.post(utils.getBaseUrl() + "/Api/service/backoffice/reset_password", {email: this.email, otp: this.otp, new_password: this.new_password})
            this.loader = false;
            if (result.data.res === 'OK') {
                this.error = '';
                this.status = 'password_changed';
                this.success = 'Great, Redirecting you to the login page.';
                setTimeout(async ()=>{
                    await this.$router.push({ path: '/backoffice/login' })
                },2000)
            }
            else if (result.data.res === 'NOK') {
                this.error = "Something is wrong. Please try again";
                this.status = 'insert-email'
            }
            else if (result.data.res === 'NO FIELDS') {
                this.error = "Your new password is empty. Please choose your new password and click on RESET";
            }
            else {
                this.error = "Something is wrong. Please try again";
                this.status = 'insert-email'
            }
        }
    }
}
</script>

<style scoped>
    @import "../../assets/style.css"
</style>